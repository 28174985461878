<template>
    <div class="product-conflicting-container">
        <div>
            <div class="product-conflicting-content">
                <div class="product-conflicting-back">
                    <router-link to="/account">
                        <chevron :iconDirection="'left'" />
                    </router-link>
                </div>
                <div class="product-conflicting-title">
                    {{ $t("profil_update_title") }}
                </div>
            </div>
            <div class="product-conflicting-buttonsgroup">
                <div>
                    <label>{{ $t("firstname") | capitalize }}</label>
                    <input v-model="name" @input="checkFirstName" type="text" class="product-conflicting-button" 
                    :placeholder="getUserSetting.firstname" />
                </div>
                <div>
                    <label>{{ $t("lastname") | capitalize }}</label>
                    <input v-model="lastname" @input="checkLastName" type="text" class="product-conflicting-button" 
                    :placeholder="getUserSetting.lastname" />
                </div>
                <!-- Do not show date, input date is not supported correctly on Safari(placeholder and preset issues)
                    Anyway input date for a birthday is wrong, because year selection is painful ...
                    Make a special component to input birthday as DD MM YYYY, text, preferrably according to local  -->
                    <!-- <div>
                        <label>{{ $t("birthdate") | capitalize }}</label>
                        <input v-model="birthday" type="date" 
                        @input="checkBirthday"
                        class="product-conflicting-button" 
                        :placeholder="formattedBirthday"
                        :class="{ 'is-invalid': isBirthdayInvalid }" />
                </div> -->
            </div>
        </div>
        <div class="product-conflicting-footer">
            <b-button class="subscription-button" :disabled="!isAbleToSave"
            block @click="submitProfilSettings()" variant="primary">
                {{ $t("Save") }}
            </b-button>
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import Chevron from '../svg/chevron.vue';
export default {
    components: { Chevron },
    name: "AccountProfilUpdate",
    data() {
        return {
            lastname: null,
            name: null,
            birthday: null,
            today: null,
            isFirstNameInvalid: false,
            isLastNameInvalid: false,
            isBirthdayInvalid: false
        };
    },
    computed: {
        ...mapGetters("users", ["getUserSetting"]),
        formattedBirthday() {
            if (this.getUserSetting && this.getUserSetting.birthday) {
                let dateParts = this.getUserSetting.birthday.split("-");
                return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
            }
            return null;
        },
        isAbleToSave() {
            // Name and Last Name are mandatory, birthday is optional
            if (this.name && this.lastname) {
                // If either firstname or lastname is different, we can save
                if (this.name != this.getUserSetting.firstname) {
                    return true
                }
                if (this.lastname != this.getUserSetting.lastname) {
                    return true
                }
                // if both firstname and lastname are the same, we can save only if birthdate is different
                return this.isBirthdayValid && this.birthday != this.getUserSetting.birthday

            }
            else {
                return false
            }
            
        },
        // isFirstNameValid() {
        //     return this.name && this.getUserSetting && this.name.toLowerCase() !== this.getUserSetting.firstname.toLowerCase();
        // },
        // isLastNameValid() {
        //     return this.lastname && this.getUserSetting && this.lastname.toLowerCase() !== this.getUserSetting.lastname.toLowerCase();
        // },
        isBirthdayValid() {
            return !this.isBirthdayInvalid //this.birthday && this.getUserSetting && this.birthday !== this.getUserSetting.birthday;
        },
    },
    methods: {
        ...mapActions("users", ["updateUserProfile", "fetchUserData", "fetchUserSetting"]),
        async submitProfilSettings() {
            try {
                let isDataChanged = false;
                let userData = {};

                if(this.isAbleToSave) { // name is always prefilled by getUsersetting.firstname.
                userData.firstname = this.name;
                isDataChanged = true;
                }
                if(this.isAbleToSave) { // lastname is always prefilled by getUserSetting.lastname.
                userData.lastname = this.lastname;
                isDataChanged = true;
                }
                if(this.isBirthdayValid) {
                userData.birthday = this.birthday;
                isDataChanged = true;
                }

                if(isDataChanged) {
                let response = await this.updateUserProfile(userData);
                
                if (response.status === 200) {
                    await this.fetchUserData();
                    await this.fetchUserSetting();
                } else {
                    console.log('Error during the update');
                }
                } else {
                    console.log('Data has not changed, no server update required');
                }
            } catch (error) {
                console.error("Error in submitProfilSettings: ", error);
            } finally {
                window.location.href = '/account';
            }
        },
        checkFirstName() {
            // this.isFirstNameInvalid = this.name && this.name.toLowerCase() === this.getUserSetting.firstname.toLowerCase();
        },
        checkLastName() {
            // this.isLastNameInvalid = this.lastname && this.lastname.toLowerCase() === this.getUserSetting.lastname.toLowerCase();
        },
        checkBirthday() {
            this.isBirthdayInvalid = !(this.birthday && this.birthday < this.today)
        },
        formatDate(dateString) {

        
            var d = new Date(dateString);
            var month = '' + (d.getMonth() + 1);
            if (month.length < 2) {
                month = '0' + month;
            }
            var day = '' + (d.getDate() + 1);
            if (day.length < 2) {
                day = '0' + day;
            }
            let year = d.getFullYear();
            return [year, month, day].join('-');
        }
    },
    async mounted() {
        await this.fetchUserData();
        await this.fetchUserSetting();
        
        window.zE("webWidget", "hide");
        // It is easier to prefill name and lastname directly with current value.
        if (this.getUserSetting.firstname) {
            this.name = this.getUserSetting.firstname
        }

        if (this.getUserSetting.lastname) {
            this.lastname = this.getUserSetting.lastname
        }

        this.today = moment().format("YYYY-MM-DD")
    },
};
</script>
  
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.product-conflicting-container {
    height: 90vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-conflicting-content {
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 1.5em;
    }

    .product-conflicting-footer {
        display: flex;
        gap: 1em;
        padding: 1.5em;
        width: 100%;
    }

    .product-conflicting-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-size: 28px;
        text-align: left;
    }

    .product-alert-text {
        text-align: left;
        color: #f95658 !important;
        font-weight: 500 !important;
    }

    .image-container {
        flex: 1;
        display: flex;
        position: relative;
        justify-content: center;
        overflow: hidden;
    }

    .product-conflicting {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        &-image {
            position: absolute;
            width: 50%;
            bottom: 0;
        }

        &-buttonsgroup {
            padding: 1.5em;
            display: flex;
            flex-direction: column;
            gap: 1em;
            width: 100%;

            .product-conflicting-button.is-invalid {
                border: 2px solid #EB0000 !important;
                font-weight: 500 !important;
                background: transparent !important;
            }

            .product-conflicting-button {
                border-radius: 18px;
                border: 2px solid var(--color-neutral-shades-300, #D9D9D9);
                background: var(--color-neutral-shades-0, #FFF);
                width: 100%;
                padding: 1em;

                &:focus {
                    box-shadow: none;
                }

                &:focus-visible {
                    outline: none;
                }

                &-finish {
                    font-size: 18px;
                    padding: 1em;
                }

                &-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                }

                &-text {
                    width: 90%;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #555b62;
                }
            }

            .btn-secondary:not(:disabled):not(.disabled).is-invalid {
                border: 1px solid #f95658 !important;
                background: #feeeee;
                box-shadow: 0px 0px 0px 1px #feeeee !important;
            }

            .btn-secondary:not(:disabled):not(.disabled).active {
                border: 1px solid #0090f8 !important;
                box-shadow: 0px 0px 0px 1px #0090f8 !important;

                .product-conflicting-button-text {
                    color: black;
                }
            }

            .account-expand-summary {
                display: flex;
                flex-direction: column;
                gap: 0.5em;

                .expand-summary-item {
                    font-family: "Open Sans";
                    font-weight: 400;
                    font-size: 14px;
                    color: #555b62;
                    text-align: left;

                    &:last-child {
                        padding-bottom: 1em;
                    }
                }

                .expand-summary-item.is-invalid {
                    color: #f95658 !important;
                    font-weight: 500 !important;
                    border: none !important;
                    background: transparent !important;
                }
            }
        }
    }
}
label {
    color: var(--color-neutral-body, #3B3B3B);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: .4em;
}
.account-current-email-content {
    width: 100%;
    border-radius: 18px;
    border: 2px solid rgba(217, 217, 217, 0.00);
    background: var(--color-primary-300, #E0F0FF);
    padding: 1em;
    color: var(--color-neutral-body, #3B3B3B);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}
.btn {
  background: radial-gradient(135.75% 139.94% at 0% 50%, #418FDE 0%, #1D60E0 43.64%);
  border-color: transparent;
  border-radius: 18px;
  padding: 1.125em 4.8em;
  box-shadow: 0px 5px #174396;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  color: #F1F8FD;
  transition: background opacity color box-shadow .5s ease;
}
.btn.disabled, .btn:disabled {
    background: #F0F0F0;
    opacity: 1;
    color: #8C8C8C;
    box-shadow: 0px 5px #F0F0F0;
}
</style>